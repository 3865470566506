<template>
  <v-layout>
    <v-btn
      v-if="currentService"
      style="height: 64px; width: 100%; border-radius: 0px, box-shadow: unset;"
      fill-height
      class="d-flex justify-start secondary-background"
      align-center
      depressed
      @click="routeWindow"
    >
      <v-icon left large>keyboard_arrow_left</v-icon>
      <v-spacer />
      <div class="pa-0 d-flex flex-column align-end mt-2">
        <span class="font-weight-bold align-self-start"> {{ currentService.name }} </span>
        <v-chip
          x-small
          outlined
          class="mt-1 grow-0 shrink-1 align-self-end"
          color="green"
          ><span> {{ currentService.type }} </span></v-chip
        >
      </div>
    </v-btn>
    <v-divider></v-divider>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
      ...mapGetters("rasaServices", {
      currentService: "currentService",
      currentServiceType: "currentServiceType",
    }),
  },
  methods: {
    ...mapActions("rasaConversation", {
      clearCurrentConversation: "clearCurrentConversation",
    }),
    routeWindow() {
      this.clearCurrentConversation();
      this.$router.push(`/customerService2/${this.currentServiceType}` ).catch((err) => { });
    },
  },
};
</script>

<style>
</style>